import Repository from 'Repository';

export class TimesheetRepository extends Repository {

    constructor() {
        super();
        this.resource = 'timesheets';
        this.idName = 'timesheet_id';
    }
}

const Timesheets = new TimesheetRepository();

export default Timesheets;
