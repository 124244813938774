import Timesheets from "@Vault/Timesheets/repositories/TimesheetRepository";

export default {
    data() {
        return {
            timesheet_categories: [],
            loading_timesheet_categories: false,
        };
    },

    methods: {
        loadTimesheetCategories() {
            if ( this.timesheet_categories.length ) {
                return;
            }

            this.loading_timesheet_categories = true;

            Timesheets.get('categories', {enabled: 1})
                .then(({data}) => {
                    this.timesheet_categories = data;
                })
                .catch(() => {
                    this.$toast({
                        type: 'error',
                        message: 'Failed loading timesheet categories.',
                    });
                })
                .finally(() => {
                    this.loading_timesheet_categories = false;
                });
        },
    },
}
