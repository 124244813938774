import {pad_left} from "kickstart-ui/lib/strings";

export default {
    methods: {
        getFirstDayOfWeek(date = null) {
            if ( !date ) {
                date = new Date();
            }

            const today = new Date(date);


            const day = today.getDay();
            const diff = today.getDate() - day + (day === 0 ? -6 : 1);

            return new Date(today.setDate(diff));
        },

        dateToString(date) {
            if ( !date ) {
                return '';
            }

            return `${date.getFullYear()}-${pad_left(date.getMonth()+1, 0, 2)}-${pad_left(date.getDate(), 0, 2)}`;
        },
    },
}
