export default {
    methods: {
        /**
         * return difference between two times in hours
         * @param start_time
         * @param end_time
         * @returns {string|number}
         */
        calculateTimeDiff(start_time, end_time) {
            if ( !start_time || !end_time ) {
                return '';
            }

            let [start_hours, start_minutes] = start_time.split(':');
            let start_date = (new Date);
            start_date.setHours(start_hours);
            start_date.setMinutes(start_minutes);

            let [end_hours, end_minutes] = end_time.split(':');
            let end_date = (new Date);
            end_date.setHours(end_hours);
            end_date.setMinutes(end_minutes == 59 ? 60 : end_minutes);

            let result = (end_date.getTime() - start_date.getTime()) / 1000 / 60 / 60;
            return Math.round(result * 100) / 100;
        },
    },
}
